<template>
<div>
    <span v-for="(it, id) in swal.scolor" :key="id">
        <v-alert dense v-if="it=='success'" :type="it" dismissible>
            <ul v-for="(item, index) in swal.message" :key="index">
                <li>{{item}}</li>
            </ul>
        </v-alert>
        <v-alert dense v-if="it=='error'" :type="it" dismissible>
            <ul v-for="(item, index) in swal.emessage" :key="index">
                <li>{{item}}</li>
            </ul>
        </v-alert>
    </span>
    <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
    <template v-slot:item="{ item }">
        <v-breadcrumbs-item
            href="#"
            :disabled="item.disabled"
        >
            {{ item.text }}
        </v-breadcrumbs-item>
    </template>
    </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
            <v-card-title>Update Car Park</v-card-title>
            <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('edit')" lazy-validation>
                <v-overlay :value="modal_loader">
                    <v-progress-circular
                        indeterminate
                        size="64"
                    ></v-progress-circular>
                </v-overlay>
                <v-container class="fill-height"
                fluid >
                    <v-row style="border: 1px solid grey; margin: 1px">
                        <v-col cols="12" md="12">
                                <v-row dense>
                                    <v-col cols="12" md="12"><span class="text-body-2">Car Park Info</span></v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-divider class="my-2"></v-divider>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                    <v-autocomplete background-color="white"
                                        dense
                                        v-model="system.selected"
                                        @change="getAllOperator"
                                        :items="system.items"
                                        item-text="name"
                                        item-value="SystemID"
                                        label="System"
                                        placeholder="Select System"
                                        outlined
                                        required
                                        :disabled="readonly"
                                    ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                    <v-autocomplete background-color="white"
                                        dense
                                        v-model="form[35].selected"
                                        :items="form[35].carparkoperator"
                                        :rules="form[35].rule"
                                        item-text="name"
                                        item-value="OperatorID"
                                        label="Operator *"
                                        placeholder="Select Operator"
                                        outlined
                                        required
                                        :disabled="readonly"
                                    ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                        <v-text-field background-color="white"
                                            dense
                                            outlined
                                            v-model="modalInfo.CarParkID"
                                            label="CP ID"
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                        <v-text-field background-color="white"
                                            dense
                                            outlined
                                            v-model="form[59].carparkmappingid"
                                            :rules="form[59].rule"
                                            label="CP ID Ref."
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                        <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[52].carparkuserinputcode"
                                        :rules="form[52].rule"
                                        label="CP Code *"
                                        :disabled="readonly"
                                    ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[1].carparkname"
                                        :rules="form[1].rule"
                                        label="Car Park Name *"
                                        required
                                        :disabled="readonly"
                                    ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" v-if="componentloader">
                                        <v-file-input label="Car Park Image (Horizontal: 400 Vertical: 400) *" v-model="form[53].file" :rules="form[53].imgrule" show-size type="file" accept="image/*" :disabled="readonly"></v-file-input>
                                        <v-card class="mb-6" style="height: 200px" outlined @drop.prevent="addDropFile53" @dragover.prevent>
                                            <v-overlay
                                            :absolute="true"
                                            opacity="1"
                                            color="grey"
                                            :value="form[53].image == '' || form[53].image == undefined ? true : false"
                                            >
                                            <v-row>
                                                <v-col>
                                                    <v-icon x-large>fa fa-image</v-icon>
                                                </v-col>
                                            </v-row>
                                            </v-overlay>
                                            <img :src="form[53].image" :style="{width: '200px'}" />
                                            <v-overlay
                                            :absolute="true"
                                            :value="form[53].btn_loading"
                                            >
                                            <v-progress-circular indeterminate size="64"></v-progress-circular>
                                            </v-overlay>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" class="mb-4">
                                        <span class="text-body-2">Parking Rate *</span>
                                        <text-editor v-if="componentloader" v-model="form[57].carparkdescription" :value="form[57].carparkdescription" :style="readonly ? {'pointer-events': 'none'} : ''" color="white"></text-editor>
                                        <v-input
                                            error
                                            readonly
                                            v-if="componentloader"
                                            v-model="form[57].carparkdescription"
                                            :rules="form[57].rule"
                                            height="0"
                                            class="hide-input-text"
                                            hide-details="auto"
                                        >
                                        </v-input>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[39].carparklocation"
                                        :rules="form[39].rule"
                                        label="Searchable Keywords / Location *"
                                        hint="Keywords make car park search results more accurate. Separate keywords with comma, max 10 keywords."
                                        persistent-hint
                                        required
                                        :disabled="readonly"
                                    ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-autocomplete background-color="white"
                                            dense
                                            v-model="form[58].selected"
                                            :items="form[58].carparktype"
                                            :rules="form[58].rule"
                                            item-text="name"
                                            item-value="id"
                                            label="Car Park Type *"
                                            outlined
                                            required
                                            :disabled="readonly"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[60].createddatetime"
                                        label="Created Date"
                                        required
                                        disabled
                                    ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[61].updateddatetime"
                                        label="Updated Date"
                                        required
                                        disabled
                                    ></v-text-field>
                                    </v-col>
                                    <!--
                                        <v-col cols="12" sm="12" md="6">
                                            <v-text-field background-color="white"
                                            dense
                                            type="time"
                                            outlined
                                            v-model="form[4].carparkoperationstarthour"
                                            :rules="form[4].rule"
                                            label="Operating Start Hour"
                                            :disabled="readonly"
                                        ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="6">
                                            <v-text-field background-color="white"
                                            dense
                                            type="time"
                                            outlined
                                            v-model="form[5].carparkoperationendhour"
                                            :rules="form[5].rule"
                                            label="Operating End Hour"
                                            :disabled="readonly"
                                        ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="6">
                                            <v-text-field background-color="white"
                                            dense
                                            outlined
                                            v-model="form[11].carparkemail"
                                            :rules="form[11].rule"
                                            label="Car Park Email"
                                            :disabled="readonly"
                                        ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="6">
                                            <v-text-field background-color="white"
                                            dense
                                            outlined
                                            v-model="form[12].carparkweburl"
                                            :rules="form[12].rule"
                                            label="Car Park Website"
                                            :disabled="readonly"
                                        ></v-text-field>
                                        </v-col>

                                    <v-autocomplete background-color="white"
                                        dense
                                        v-model="form[0].selected"
                                        :items="form[0].country"
                                        :rules="form[0].rule"
                                        item-text="name"
                                        item-value="CountryCode"
                                        label="Select Country"
                                        outlined
                                    ></v-autocomplete>

                                    <v-col cols="12" sm="12" md="6">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[13].carparktelephoneno1"
                                        :rules="form[13].rule"
                                        label="Car Park Phone 1"
                                        :disabled="readonly"
                                    ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[14].carparktelephoneno2"
                                        :rules="form[14].rule"
                                        label="Car Park Phone 2"
                                        :disabled="readonly"
                                    ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[15].carparkfaxno"
                                        :rules="form[15].rule"
                                        label="Car Park Fax No."
                                        :disabled="readonly"
                                    ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <span class="text-body-2">Car Park Status</span>
                                        <v-switch
                                        color="#25283D"
                                        class="col-1 pl-0"
                                        v-model="form[50].carparkactivestatus"
                                        :label="`${form[50].carparkactivestatus ? 'Enabled' : 'Disabled'}`"
                                        :disabled="readonly"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <span class="text-body-2">Show on Mobile App?</span>
                                        <v-switch
                                        color="#25283D"
                                        class="col-1 pl-0"
                                        v-model="form[51].carparkmobiledisplaystatus"
                                        :label="`${form[51].carparkmobiledisplaystatus ? 'Yes' : 'No'}`"
                                        :disabled="readonly"
                                        ></v-switch>
                                    </v-col>
                                    -->
                                </v-row>
                        </v-col>
                    </v-row>
                    <v-row style="border: 1px solid grey; margin: 1px" class="mt-4">
                        <v-col cols="12" md="12">
                            <v-row dense>
                                <v-col cols="12" md="12"><span class="text-body-2">Car Park Web Payment</span></v-col>
                                <v-col cols="12" sm="12" md="12">
                                        <v-divider class="my-2"></v-divider>
                                    </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <span class="text-body-2">LPR</span>
                                    <div style="display: flex">
                                    <v-switch
                                    color="success"
                                    v-model="form[65].carparklprwebstatus"
                                    @change="showLPRWeb()"
                                    :label="`${form[65].carparklprwebstatus == 1 ? 'Yes' : 'No'}`"
                                    :disabled="readonly || validateAccess('carpark_webpayment_update') == false"
                                    class="pr-4"
                                    ></v-switch>
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        type="text"
                                        v-model="form[66].carparkwebpaymentlprurl"
                                        :rules="form[66].rule"
                                        label="URL Name"
                                        :disabled="(form[65].carparklprwebstatus == '0' || readonly ) || validateAccess('carpark_webpayment_update') == false"
                                        :color="hasVerified ? 'success': 'danger'"
                                        :bg-color="hasVerified ? 'success': 'danger'"

                                    ></v-text-field>
                                    </div>

                                </v-col>
                                <v-col cols="12" md="12" v-if="form[66].carparkwebpaymentlprurl != ''  && form[66].carparkwebpaymentlprurl != modalInfo.CarParkLPRWebPaymentName && hasVerified == false">
                                    <v-btn color="primary" @click="verifyUniqueName(form[66].carparkwebpaymentlprurl)">Verify URL Name</v-btn>
                                </v-col>
                                <v-col cols="12" md="12" v-if="form[66].carparkwebpaymentlprurl != ''  && form[66].carparkwebpaymentlprurl != modalInfo.CarParkLPRWebPaymentName && hasVerified == true">
                                    <v-btn color="info" @click="updateUniqueName(form[66].carparkwebpaymentlprurl, 1)" >Update URL Name</v-btn>
                                </v-col>
                                <v-col cols="12" md="12" v-if=" form[66].carparkwebpaymentlprurl != null && form[66].carparkwebpaymentlprurl != ''  && form[66].carparkwebpaymentlprurl == modalInfo.CarParkLPRWebPaymentName">
                                    <v-col cols="12" md="12" oncontextmenu="return false" onselectstart="return false" onpaste="return false;" onCopy="return false" onCut="return false" onDrag="return false" onDrop="return false">
                                        <qrcode-vue id="lpr-qr" class="qr-canvas" :value="'https://devspconsole.snatchpark.com/lprpark?carpark='+form[66].carparkwebpaymentlprurl" size="200"></qrcode-vue>
                                        <div style="display: none"><qrcode-vue class="qr-canvas" :value="'https://devspconsole.snatchpark.com/lprpark?carpark='+form[66].carparkwebpaymentlprurl" size="800"></qrcode-vue></div>
                                    </v-col>
                                    <v-btn color="success" @click="downloadPNG('lpr-qr')">Download QR</v-btn>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <span class="text-body-2">Baylock</span>
                                    <div style="display: flex">
                                    <v-switch
                                    color="success"
                                    v-model="form[67].carparkwlwebstatus"
                                    @change="showWLWeb()"
                                    :label="`${form[67].carparkwlwebstatus == 1 ? 'Yes' : 'No'}`"
                                    :disabled="readonly || validateAccess('carpark_webpayment_update') == false"
                                    class="pr-4"
                                    ></v-switch>
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        type="text"
                                        v-model="form[68].carparkwebpaymentwlurl"
                                        :rules="form[68].rule"
                                        label="URL Name"
                                        :color="hasVerified ? 'success': 'danger'"
                                        :disabled="(form[67].carparkwlwebstatus == '0' || readonly)  || validateAccess('carpark_webpayment_update') == false"
                                    ></v-text-field>
                                    </div>

                                </v-col>
                                <v-col cols="12" md="12" v-if="form[68].carparkwebpaymentwlurl != ''  && form[68].carparkwebpaymentwlurl != modalInfo.CarParkWLWebPaymentName  && hasVerified == false">
                                    <v-btn color="primary" @click="verifyUniqueName(form[68].carparkwebpaymentwlurl)">Verify URL Name</v-btn>
                                </v-col>
                                <v-col cols="12" md="12" v-if="form[68].carparkwebpaymentwlurl != ''  && form[68].carparkwebpaymentwlurl != modalInfo.CarParkWLWebPaymentName && hasVerified == true">
                                    <v-btn color="info" @click="updateUniqueName(form[68].carparkwebpaymentwlurl, 2)" >Update URL Name</v-btn>
                                </v-col>
                                <v-col cols="12" md="12" v-if=" form[68].carparkwebpaymentwlurl != null && form[68].carparkwebpaymentwlurl != '' && form[68].carparkwebpaymentwlurl == modalInfo.CarParkWLWebPaymentName">
                                    <v-col cols="12" md="12" oncontextmenu="return false" onselectstart="return false" onpaste="return false;" onCopy="return false" onCut="return false" onDrag="return false" onDrop="return false">
                                        <qrcode-vue id="wl-qr" class="qr-canvas" :value="'https://devspconsole.snatchpark.com/wlockpark?carpark='+form[68].carparkwebpaymentwlurl" size="200"></qrcode-vue>
                                        <div style="display: none"><qrcode-vue class="qr-canvas" :value="'https://devspconsole.snatchpark.com/wlockpark?carpark='+form[68].carparkwebpaymentwlurl" size="800"></qrcode-vue></div>
                                    </v-col>
                                    <v-btn color="success" @click="downloadPNG('wl-qr')">Download QR</v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row style="border: 1px solid grey; margin: 1px" class="mt-4">
                        <v-col cols="12" md="12">
                            <v-row dense>
                                <v-col cols="12" md="12"><span class="text-body-2">Car Park Bay</span></v-col>
                                <v-col cols="12" sm="12" md="12">
                                        <v-divider class="my-2"></v-divider>
                                    </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        type="number"
                                        v-model="form[6].carparktotalbaycount"
                                        :rules="form[6].rule"
                                        label="Total Bay Count *"
                                        :disabled="readonly"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        type="number"
                                        v-model="form[22].carparktotalbayavailable"
                                        :rules="form[22].rule"
                                        label="Total Available Bay *"
                                        :disabled="readonly"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <span class="text-body-2">WheelLock</span>
                                    <div style="display: flex">
                                    <v-switch
                                    color="success"
                                    v-model="form[63].carparkwheellockstatus"
                                    :label="`${form[63].carparkwheellockstatus == 1 ? 'Yes' : 'No'}`"
                                    :disabled="readonly"
                                    class="pr-4"
                                    ></v-switch>
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        type="number"
                                        v-model="form[62].carparkmanagedbaycount"
                                        :rules="form[62].rule"
                                        label="Total WheelLock Bay"
                                        disabled
                                    ></v-text-field>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <span class="text-body-2"><br/></span>
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[64].bayprefix"
                                        :rules="form[64].rule"
                                        label="Bay Prefix"
                                        :disabled="form[63].carparkwheellockstatus == '0' || readonly"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row style="border: 1px solid grey; margin: 1px" class="mt-4">
                        <v-col cols="12" md="12">
                                <v-row dense>
                                    <v-col cols="12" md="12"><span class="text-body-2">Car Park Operation</span></v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-divider class="my-2"></v-divider>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                        <vc-date-picker v-if="componentloader" v-model="form[40].range.start" popover mode="date" :masks="{ input: 'DD/MM/YYYY' }">
                                        <template v-slot="{ inputValue, inputEvents, togglePopover }">
                                            <v-row dense>
                                                <v-col cols="12" sm="12" md="12">
                                                    <div
                                                    v-bind:class="[ focused ? 'v-input--is-focused primary--text' : '', readonly ? 'v-input--is-disabled' : '' ]"
                                                    class="v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined">
                                                    <div class="v-input__control">
                                                    <div class="v-input__slot white">
                                                        <fieldset aria-hidden="true"><legend :style="focused || form[40].range.start ? {'width': '145px'} : {'width': '0px'}"><span>&#8203;</span></legend></fieldset>
                                                        <div class="v-text-field__slot">
                                                        <label for="input-startdate" v-bind:class="[ focused || form[40].range.start ? 'primary--text v-label--active' : '', readonly ? 'v-label--is-disabled' : '' ]" class="v-label theme--light" style="left: 0px; right: auto; position: absolute;">Car Park Operation Start Date</label>
                                                        <input
                                                        id="input-startdate"
                                                        @focus="focused = true"
                                                        @blur="focused = false"
                                                        :value="inputValue"
                                                        v-on="inputEvents"
                                                        @click="togglePopover({ placement: 'top-start', visibility: 'click' })"
                                                        autocomplete="off"
                                                        :disabled="readonly"
                                                        />
                                                        </div>
                                                    </div>
                                                    <div class="v-text-field__details">
                                                        <v-input
                                                            error
                                                            readonly
                                                            v-model="form[40].range.start"
                                                            :rules="form[40].rulestart"
                                                            height="0"
                                                            class="hide-input-text"
                                                        >
                                                        </v-input>
                                                    </div>
                                                    </div>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </template>
                                        </vc-date-picker>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                        <vc-date-picker v-if="componentloader" v-model="form[40].range.end" popover mode="date" :masks="{ input: 'DD/MM/YYYY' }">
                                        <template v-slot="{ inputValue, inputEvents, togglePopover }">
                                            <v-row dense>
                                                <v-col cols="12" sm="12" md="12">
                                                    <div
                                                    v-bind:class="[ focusedend ? 'v-input--is-focused primary--text' : '', readonly ? 'v-input--is-disabled' : '' ]"
                                                    class="v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined">
                                                    <div class="v-input__control">
                                                    <div class="v-input__slot white">
                                                        <fieldset aria-hidden="true"><legend :style="focusedend || form[40].range.end ? {'width': '145px'} : {'width': '0px'}"><span>&#8203;</span></legend></fieldset>
                                                        <div class="v-text-field__slot">
                                                        <label for="input-enddate" v-bind:class="[ focusedend || form[40].range.end ? 'primary--text v-label--active' : '', readonly ? 'v-label--is-disabled' : '' ]" class="v-label theme--light" style="left: 0px; right: auto; position: absolute;">Car Park Operation End Date</label>
                                                        <input
                                                        id="input-enddate"
                                                        @focus="focusedend = true"
                                                        @blur="focusedend = false"
                                                        :value="inputValue"
                                                        v-on="inputEvents"
                                                        @click="togglePopover({ placement: 'top-start', visibility: 'click' })"
                                                        autocomplete="off"
                                                        :disabled="readonly"
                                                        />
                                                        </div>
                                                    </div>
                                                    <div class="v-text-field__details">
                                                        <v-input
                                                            error
                                                            readonly
                                                            v-model="form[40].range.end"
                                                            :rules="form[40].ruleend"
                                                            height="0"
                                                            class="hide-input-text"
                                                        >
                                                        </v-input>
                                                    </div>
                                                    </div>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </template>
                                        </vc-date-picker>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                        <div v-bind:class="[ focusstarthour ? 'v-input--is-focused primary--text' : '', readonly ? 'v-input--is-disabled' : '' ]"
                                            class="v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined">
                                            <div class="v-input__control">
                                                <div class="v-input__slot white">
                                                    <fieldset aria-hidden="true"><legend :style="focusstarthour || form[4].carparkoperationstarthour ? {'width': '100px'} : {'width': '0px'}"><span>&#8203;</span></legend></fieldset>
                                                    <div class="v-text-field__slot">
                                                    <label for="input-starthour" v-bind:class="[ focusstarthour || form[4].carparkoperationstarthour ? 'primary--text v-label--active' : '' ]" class="v-label theme--light" style="left: 0px; right: auto; position: absolute;">Operating Start Hour *</label>
                                                    <vue-timepicker placeholder=" " style="padding: none" id="input-starthour" input-class="vtimepicker" @focus="focusstarthour = true"
                                                                    @blur="focusstarthour = false" v-model="form[4].carparkoperationstarthour" format="h:mm a" manual-input :disabled="readonly">
                                                        <template v-slot:clearButton>
                                                            <v-icon x-small>fa fa-times</v-icon>
                                                        </template>
                                                    </vue-timepicker>
                                                    </div>
                                                </div>
                                                <div class="v-text-field__details">
                                                    <v-input
                                                        error
                                                        readonly
                                                        v-model="form[4].carparkoperationstarthour"
                                                        :rules="form[4].rule"
                                                        height="0"
                                                        class="hide-input-text"
                                                    >
                                                    </v-input>
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                        <div v-bind:class="[ focusendhour ? 'v-input--is-focused primary--text' : '', readonly ? 'v-input--is-disabled' : '' ]"
                                            class="v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined">
                                            <div class="v-input__control">
                                                <div class="v-input__slot white">
                                                    <fieldset aria-hidden="true"><legend :style="focusendhour || form[5].carparkoperationendhour ? {'width': '100px'} : {'width': '0px'}"><span>&#8203;</span></legend></fieldset>
                                                    <div class="v-text-field__slot">
                                                    <label for="input-starthour" v-bind:class="[ focusendhour || form[5].carparkoperationendhour ? 'primary--text v-label--active' : '' ]" class="v-label theme--light" style="left: 0px; right: auto; position: absolute;">Operating End Hour *</label>
                                                    <vue-timepicker placeholder=" " style="padding: none" id="input-starthour" input-class="vtimepicker" @focus="focusendhour = true"
                                                                    @blur="focusendhour = false" v-model="form[5].carparkoperationendhour" format="h:mm a" manual-input :disabled="readonly">
                                                        <template v-slot:clearButton>
                                                            <v-icon x-small>fa fa-times</v-icon>
                                                        </template>
                                                    </vue-timepicker>
                                                    </div>
                                                </div>
                                                <div class="v-text-field__details">
                                                    <v-input
                                                        error
                                                        readonly
                                                        v-model="form[5].carparkoperationendhour"
                                                        :rules="form[5].rule"
                                                        height="0"
                                                        class="hide-input-text"
                                                    >
                                                    </v-input>
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                        </v-col>
                    </v-row>
                    <v-row style="border: 1px solid grey; margin: 1px" class="mt-4">
                        <v-col cols="12" md="12">
                                <v-row dense>
                                    <v-col cols="12" md="12"><span class="text-body-2">Car Park Person In Charge</span></v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-divider class="my-2"></v-divider>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[7].carparkcontactpersonname"
                                        :rules="form[7].rule"
                                        label="Contact Person"
                                        :disabled="readonly"
                                    ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[10].carparkcontactpersonemail"
                                        :rules="form[10].rule"
                                        label="Email"
                                        :disabled="readonly"
                                    ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[8].carparksoscontact1"
                                        :rules="form[8].rule"
                                        label="SOS Mobile No. 1"
                                        :disabled="readonly"
                                    ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[9].carparksoscontact2"
                                        :rules="form[9].rule"
                                        label="SOS Mobile No. 2"
                                        :disabled="readonly"
                                    ></v-text-field>
                                    </v-col>
                                </v-row>
                        </v-col>
                    </v-row>
                    <v-row style="border: 1px solid grey; margin: 1px" class="mt-4">
                        <v-col cols="12" md="12">
                                <v-row dense>
                                    <v-col cols="12" md="12"><span class="text-body-2">Car Park Address</span></v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-divider class="my-2"></v-divider>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[16].carparkaddress1"
                                        :rules="form[16].rule"
                                        label="Address 1 *"
                                        :disabled="readonly"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[17].carparkaddress2"
                                        :rules="form[17].rule"
                                        label="Address 2"
                                        :disabled="readonly"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[18].carparkaddress3"
                                        :rules="form[18].rule"
                                        label="Address 3"
                                        :disabled="readonly"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[19].carparkaddresspostcode"
                                        :rules="form[19].rule"
                                        label="Postcode *"
                                        @keyup="execFilter('postcode1')"
                                        :disabled="readonly"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-autocomplete background-color="white"
                                        dense
                                        v-model="form[20].selected"
                                        :items="form[20].carparkaddresscity"
                                        :rules="form[20].rule"
                                        item-text="name"
                                        item-value="CityCode"
                                        label="City"
                                        @change="execFilter('state1')"
                                        outlined
                                        disabled
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-autocomplete background-color="white"
                                        dense
                                        v-model="form[21].selected"
                                        :items="form[21].carparkaddressstate"
                                        :rules="form[21].rule"
                                        item-text="name"
                                        item-value="StateCode"
                                        label="State"
                                        outlined
                                        disabled
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-autocomplete background-color="white"
                                        dense
                                        v-model="form[0].selected"
                                        :items="form[0].carparkaddresscountry"
                                        :rules="form[0].rule"
                                        item-text="name"
                                        item-value="CountryCode"
                                        label="Country"
                                        outlined
                                        disabled
                                    ></v-autocomplete>
                                </v-col>
                                </v-row>
                        </v-col>
                    </v-row>
                    <v-row style="border: 1px solid grey; margin: 1px" class="mt-4">
                        <v-col cols="12" md="12">
                                <v-row dense>
                                    <v-col cols="12" md="12"><span class="text-body-2">Car Park Contact</span></v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-divider class="my-2"></v-divider>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[13].carparktelephoneno1"
                                        :rules="form[13].rule"
                                        label="Phone No. 1 *"
                                        :disabled="readonly"
                                    ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[14].carparktelephoneno2"
                                        :rules="form[14].rule"
                                        label="Phone No. 2"
                                        :disabled="readonly"
                                    ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[15].carparkfaxno"
                                        :rules="form[15].rule"
                                        label="Fax No."
                                        :disabled="readonly"
                                    ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field background-color="white"
                                            dense
                                            outlined
                                            v-model="form[11].carparkemail"
                                            :rules="form[11].rule"
                                            label="Car Park Email *"
                                            :disabled="readonly"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field background-color="white"
                                            dense
                                            outlined
                                            v-model="form[12].carparkweburl"
                                            :rules="form[12].rule"
                                            label="Car Park Website"
                                            :disabled="readonly"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                        </v-col>
                    </v-row>
                    <v-row style="border: 1px solid grey; margin: 1px" class="mt-4">
                        <v-col cols="12" md="12">
                                <v-row dense>
                                    <v-col cols="12" md="12"><span class="text-body-2">Geolocation</span></v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-divider class="my-2"></v-divider>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field background-color="white"
                                            dense
                                            outlined
                                            v-model="form[2].latitude"
                                            @change="setPlaceFromAPI(form[2].latitude, form[3].longitude)"
                                            :rules="form[2].rule"
                                            label="Latitude *"
                                            :disabled="readonly"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[3].longitude"
                                        @change="setPlaceFromAPI(form[2].latitude, form[3].longitude)"
                                        :rules="form[3].rule"
                                        label="Longitude *"
                                        :disabled="readonly"
                                    ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <gmap-autocomplete
                                        v-model="this.searchlocation"
                                        :class="[`search-textfield v-input__slot border-vc`]"
                                        style="background-color: white"
                                        placeholder="Search Location"
                                        :disabled="readonly"
                                        @place_changed="setPlace">
                                        </gmap-autocomplete>
                                        <GmapMap
                                        v-if="componentloader"
                                        :center="mapCenter"
                                        :zoom="zoom"
                                        style="width: 100%; height: 300px"
                                        >
                                        <GmapMarker
                                            :position="markers.position"
                                            :clickable="true"
                                            :draggable="true"
                                            @dragend="gMapFunc($event.latLng)"
                                        />
                                        </GmapMap>
                                    </v-col>
                                </v-row>
                        </v-col>
                    </v-row>
                    <v-row style="border: 1px solid grey; margin: 1px" class="mt-4">
                        <v-col cols="12" md="12">
                                <v-row dense>
                                    <v-col cols="12" md="12"><span class="text-body-2">Car Park Billing Setting</span></v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-divider class="my-2"></v-divider>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[23].carparkbillingcompanyname"
                                        :rules="form[23].rule"
                                        label="Company Name *"
                                        hint="Bills Payable To Company Name."
                                        persistent-hint
                                        :disabled="readonly"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[25].carparkbillingregno"
                                        :rules="form[25].rule"
                                        label="Company Reg No."
                                        :disabled="readonly"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[24].carparkbillingsstno"
                                        :rules="form[24].rule"
                                        label="SST Reg No."
                                        :disabled="readonly"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[26].carparkbillingaddress1"
                                        :rules="form[26].rule"
                                        label="Address 1 *"
                                        :disabled="readonly"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[27].carparkbillingaddress2"
                                        :rules="form[27].rule"
                                        label="Address 2"
                                        :disabled="readonly"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[28].carparkbillingaddress3"
                                        :rules="form[28].rule"
                                        label="Address 3"
                                        :disabled="readonly"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[29].carparkbillingaddresspostcode"
                                        :rules="form[29].rule"
                                        label="Postcode *"
                                        @keyup="execFilter('postcode2')"
                                        :disabled="readonly"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-autocomplete background-color="white"
                                        dense
                                        v-model="form[30].selected"
                                        :items="form[30].carparkbillingaddresscity"
                                        :rules="form[30].rule"
                                        item-text="name"
                                        item-value="CityCode"
                                        label="City"
                                        @change="execFilter('state2')"
                                        outlined
                                        disabled
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-autocomplete background-color="white"
                                        dense
                                        v-model="form[31].selected"
                                        :items="form[31].carparkbillingaddressstate"
                                        :rules="form[31].rule"
                                        item-text="name"
                                        item-value="StateCode"
                                        label="State"
                                        outlined
                                        disabled
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-autocomplete background-color="white"
                                        dense
                                        v-model="form[32].selected"
                                        :items="form[32].carparkbillingaddresscountry"
                                        :rules="form[32].rule"
                                        item-text="name"
                                        item-value="CountryCode"
                                        label="Country"
                                        outlined
                                        disabled
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[33].carparkbillingtelephoneno"
                                        :rules="form[33].rule"
                                        label="Phone No."
                                        :disabled="readonly"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[34].carparkbillingfaxno"
                                        :rules="form[34].rule"
                                        label="Fax No."
                                        :disabled="readonly"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[36].carparkbillingemail"
                                        :rules="form[36].rule"
                                        label="Email"
                                        :disabled="readonly"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[37].carparkbillingweburl"
                                        :rules="form[37].rule"
                                        label="Website"
                                        :disabled="readonly"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-if="componentloader">
                                        <v-file-input label="Header Logo (1000px x 70px) *" v-model="form[54].file" :rules="form[54].imgrule" show-size type="file" accept="image/*" :disabled="readonly"></v-file-input>
                                        <v-card class="mb-6" style="height: 200px" outlined @drop.prevent="addDropFile54" @dragover.prevent>
                                            <v-overlay
                                            :absolute="true"
                                            opacity="1"
                                            color="grey"
                                            :value="form[54].image == '' || form[54].image == undefined ? true : false"
                                            >
                                            <v-row>
                                                <v-col>
                                                    <v-icon x-large>fa fa-image</v-icon>
                                                </v-col>
                                            </v-row>
                                            </v-overlay>
                                            <img :src="form[54].image" :style="{width: '100%'}" />
                                            <v-overlay
                                            :absolute="true"
                                            :value="form[54].btn_loading"
                                            >
                                            <v-progress-circular indeterminate size="64"></v-progress-circular>
                                            </v-overlay>
                                        </v-card>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-if="componentloader">
                                        <v-file-input label="Footer Logo (1000px x 150px) *" v-model="form[55].file" :rules="form[55].imgrule" show-size type="file" accept="image/*" :disabled="readonly"></v-file-input>
                                        <v-card class="mb-6" style="height: 300px" outlined @drop.prevent="addDropFile55" @dragover.prevent>
                                            <v-overlay
                                            :absolute="true"
                                            opacity="1"
                                            color="grey"
                                            :value="form[55].image == '' || form[55].image == undefined ? true : false"
                                            >
                                            <v-row>
                                                <v-col>
                                                    <v-icon x-large>fa fa-image</v-icon>
                                                </v-col>
                                            </v-row>
                                            </v-overlay>
                                            <img :src="form[55].image" :style="{width: '100%'}" />
                                            <v-overlay
                                            :absolute="true"
                                            :value="form[55].btn_loading"
                                            >
                                            <v-progress-circular indeterminate size="64"></v-progress-circular>
                                            </v-overlay>
                                        </v-card>
                                </v-col>
                                <!--
                                <v-col cols="12" sm="12" md="12">
                                    <span class="text-body-2">Terms & Conditions</span>
                                    <text-editor v-if="componentloader" v-model="form[38].carparkbillingtermsconditions" :value="form[38].carparkbillingtermsconditions" :style="readonly ? {'pointer-events': 'none'} : ''" color="white"></text-editor>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" class="mt-4">
                                        <v-combobox
                                        v-model="form[49].selected"
                                        :items="form[49].carparkpaymentmethod"
                                        label="Payment Method"
                                        multiple
                                        item-text="name"
                                        item-value="id"
                                        outlined
                                        :rules="form[49].rule"
                                        dense
                                        required
                                        :disabled="readonly"
                                        ></v-combobox>
                                </v-col>
                                -->
                                </v-row>
                        </v-col>
                    </v-row>
                    <v-row style="border: 1px solid grey; margin: 1px" class="mt-4">
                        <v-col cols="12" md="12">
                                <v-row dense>
                                    <v-col cols="12" md="12"><span class="text-body-2">Car Park Setting</span></v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-divider class="my-2"></v-divider>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-autocomplete background-color="white"
                                            dense
                                            v-model="form[44].selected"
                                            @change="noSST()"
                                            :items="form[44].carparktaxoption"
                                            :rules="form[44].rule"
                                            item-text="name"
                                            item-value="id"
                                            label="SST Option *"
                                            :disabled="readonly"
                                            outlined
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field background-color="white"
                                        dense
                                        type="text"
                                        outlined
                                        v-model="form[45].carparktaxamount"
                                        v-mask="mask"
                                        :rules="form[45].rule"
                                        placeholder="0.xx"
                                        label="SST (%)"
                                        :disabled="form[44].selected == 'NOSST' || readonly"
                                    ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <span class="text-body-2">Car Park Status</span>
                                        <v-switch
                                        color="success"
                                        class="col-1 pl-0"
                                        v-model="form[50].carparkactivestatus"
                                        :label="`${form[50].carparkactivestatus ? 'Enabled' : 'Disabled'}`"
                                        :disabled="readonly"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <span class="text-body-2">Display on Mobile</span>
                                        <v-switch
                                        color="success"
                                        class="col-1 pl-0"
                                        v-model="form[51].carparkmobiledisplaystatus"
                                        :label="`${form[51].carparkmobiledisplaystatus ? 'Yes' : 'No'}`"
                                        :disabled="readonly"
                                        ></v-switch>
                                    </v-col>
                                </v-row>
                        </v-col>
                    </v-row>
                    <!--
                    <v-row>
                        <v-col cols="12" md="12">
                        <v-sheet style="border: 5px solid black" class="pa-5" color="white lighten-3">
                                <v-row dense>
                                    <v-col cols="12" sm="12" md="12">
                                    <span class="headline">Car Park Setting</span>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-divider class="my-4"></v-divider>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-autocomplete background-color="white"
                                            dense
                                            v-model="form[41].selected"
                                            :items="form[41].carparkcontracttype"
                                            :rules="form[41].rule"
                                            item-text="name"
                                            item-value="id"
                                            label="Select Contract Type"
                                            outlined
                                            :disabled="readonly"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-autocomplete background-color="white"
                                            dense
                                            v-model="form[42].selected"
                                            :items="form[42].carparklicenseapplicationcommencementdate"
                                            :rules="form[42].rule"
                                            item-text="name"
                                            item-value="id"
                                            label="Select Parking License Application Commencement Date"
                                            outlined
                                            :disabled="readonly"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12"><v-divider></v-divider></v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-layout row wrap>
                                        <v-checkbox :disabled="readonly" multiple dense style="flex: 0 0 50%; max-width: 50%;" v-for="(item2, index2) of form[43].carparkoptions" hide-details :value="item2.id" v-model="form[43].selected" :key="index2">
                                            <template v-slot:label class="checkbox-role"><span style="width: 100%" @click="changeOrder">{{item2.name}}</span></template>
                                        </v-checkbox>
                                        </v-layout>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12"><v-divider class="my-4"></v-divider></v-col>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[44].carparktaxoption"
                                        :rules="form[44].rule"
                                        label="Car Park Tax Option"
                                        :disabled="readonly"
                                    ></v-text-field>
                                    </v-col>
                                </v-row>
                        </v-sheet>
                        </v-col>
                        <v-col cols="12" md="12">
                        <v-sheet style="border: 5px solid black" class="pa-5" color="white lighten-3">
                                <v-row dense>
                                    <v-col cols="12" sm="12" md="12">
                                    <span class="headline">Car Park Fees</span>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-divider class="my-4"></v-divider>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[46].carparkfeereactivation"
                                        :rules="form[46].rule"
                                        label="Reactivation Fee (RM)"
                                        :disabled="readonly"
                                    ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[47].carparkfeelatepaymentpenalty"
                                        :rules="form[47].rule"
                                        label="Late Payment Penalty (RM)"
                                        :disabled="readonly"
                                    ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[48].carparkfeereinstatement"
                                        :rules="form[48].rule"
                                        label="Reinstatement Fee (RM)"
                                        :disabled="readonly"
                                    ></v-text-field>
                                    </v-col>
                                </v-row>
                        </v-sheet>
                        </v-col>
                        <v-col cols="12" md="12">
                        <v-sheet style="border: 5px solid black" class="pa-5" color="white lighten-3">
                                <v-row dense>
                                    <v-col cols="12" sm="12" md="12">
                                    <span class="headline">General Document Setting</span>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" v-if="componentloader">
                                        <v-file-input label="General Document Image" v-model="form[56].file" :rules="form[56].imgrule" show-size type="file" accept="image/*" :disabled="readonly"></v-file-input>
                                        <v-card class="mb-6" style="height: 200px" outlined @drop.prevent="addDropFile56" @dragover.prevent>
                                            <v-overlay
                                            :absolute="true"
                                            opacity="1"
                                            color="grey"
                                            :value="form[56].image == '' || form[56].image == undefined ? true : false"
                                            >
                                            <v-row>
                                                <v-col>
                                                    <v-icon x-large>fa fa-image</v-icon>
                                                </v-col>
                                            </v-row>
                                            </v-overlay>
                                            <img :src="form[56].image" :style="{width: '100%'}" />
                                            <v-overlay
                                            :absolute="true"
                                            :value="form[56].btn_loading"
                                            >
                                            <v-progress-circular indeterminate size="64"></v-progress-circular>
                                            </v-overlay>
                                        </v-card>
                                    </v-col>
                                </v-row>
                        </v-sheet>
                        </v-col>
                    </v-row>
                    -->
                </v-container>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn v-if="!readonly" tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn>
                    <v-btn tile color="blue darken-1" text @click="goBack">Close</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
</div>
</template>
<script>
import alertbox from '../modals/Alert';
import TextEditor from '../TextEditor';
import * as moment from "moment";
import * as tz from "moment-timezone";
import * as AppStorage from '../../assets/js/storage';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import {rule} from '../../assets/js/checkPerm';
import QrcodeVue from 'qrcode.vue'
import { saveAs } from 'file-saver'
export function timeMask(value) {
    /*
    const hours = [
      /[0]/, /[0, .]{1}/
    ];

    let minutes;

    if (value.charAt(1) === '.')
    minutes = [/[0-9]/, /[0-9]/];

    else
    minutes = ['.', /[0-9]/, /[0-9]/];

    return value.length > 2
      ? [...hours, ...minutes]
      : hours;
    */
   const hours = [/['0']/, '.'];
   const minutes = [/[0-9]/, /[0-9]/];

    return [...hours, ...minutes];
}

export default {
    name: 'Update Car Park',
    title: 'Update Car Park',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Car Park',
                disabled: true,
                href: '#',
                },

                {
                text: 'Car Park',
                disabled: true,
                href: '#',
                },

                {
                text: 'Update Car Park',
                disabled: true,
                href: '#',
                },
            ],
            dialog_local: false,
            innerModal: false,
            form: [ 
                {carparkaddresscountry: [], rule: [], selected: '' },
                {carparkname: '', rule: [] },
                {latitude: '', rule: [] },
                {longitude: '', rule: [] },
                {carparkoperationstarthour: '', rule: [] },
                {carparkoperationendhour: '', rule: [] },
                {carparktotalbaycount: '', rule: [] },
                {carparkcontactpersonname: '', rule: [] },
                {carparksoscontact1: '', rule: [] },
                {carparksoscontact2: '', rule: [] },
                {carparkcontactpersonemail: '', rule: [] },
                {carparkemail: '', rule: [] },
                {carparkweburl: '', rule: [] },
                {carparktelephoneno1: '', rule: [] },
                {carparktelephoneno2: '', rule: [] },
                {carparkfaxno: '', rule: [] },
                {carparkaddress1: '', rule: [] },
                {carparkaddress2: '', rule: [] },
                {carparkaddress3: '', rule: [] },
                {carparkaddresspostcode: '', rule: [], selected: '' },
                {carparkaddresscity: [], rule: [], selected: '' },
                {carparkaddressstate: [], rule: [], selected: '' },
                {carparktotalbayavailable: '', rule: [] },
                {carparkbillingcompanyname: '', rule: [] },
                {carparkbillingsstno: '', rule: [] },
                {carparkbillingregno: '', rule: [] },
                {carparkbillingaddress1: '', rule: [] },
                {carparkbillingaddress2: '', rule: [] },
                {carparkbillingaddress3: '', rule: [] },
                {carparkbillingaddresspostcode: '', rule: [] },
                {carparkbillingaddresscity: [], rule: [], selected: '' },
                {carparkbillingaddressstate: [], rule: [], selected: '' },
                {carparkbillingaddresscountry: [], rule: [], selected: '' },
                {carparkbillingtelephoneno: '', rule: [] },
                {carparkbillingfaxno: '', rule: [] },
                {carparkoperator: [], rule: [], selected: '', carparkoperatorall: [] },
                {carparkbillingemail: '', rule: [] },
                {carparkbillingweburl: '', rule: [] },
                {carparkbillingtermsconditions: '', rule: [] },
                {carparklocation: '', rule: [] },
                {range: {start: '', end: ''}, rulestart: [], ruleend: [] }, //carparkoperation start date
                {carparkcontracttype: [{id: '', name: 'Select'}, {id: 'Tenancy Deal', name: 'Tenancy Deal'}, {id: 'Management Deal', name: 'Management Deal'}], rule: [], selected: '' },
                {carparklicenseapplicationcommencementdate: [
                    {id: '', name: 'Select'}, 
                    {id: 'Allow Half Month (1st & 16th)', name: 'Allow Half Month (1st & 16th)'}, 
                    {id: 'Allow Full Month Only (1st)', name: 'Allow Full Month Only (1st)'},
                    {id: 'Allow Any Day of Month', name: 'Allow Any Day of Month'},
                    ],
                    rule: [], selected: ''
                },
                {carparkoptions: [
                    {id: 'Enable Car Park', name: 'Enable Car Park'}, 
                    {id: 'Exemption from Late Payment', name: 'Exemption from Late Payment'}, 
                    {id: 'Exempted from SST', name: 'Exempted from SST'},
                    {id: 'ParkAide Enable (Force)', name: 'ParkAide Enable (Force)'},
                    {id: 'Suspend from Parking Application', name: 'Suspend from Parking Application'},
                    {id: 'Exemption from Stop Billing', name: 'Exemption from Stop Billing'},
                    {id: 'Car Park No SST', name: 'Car Park No SST'},
                    {id: 'ParkAide Enable (Optional)', name: 'ParkAide Enable (Optional)'},
                    ],
                    rule: [], selected: []
                },
                {carparktaxoption: [
                    {id: '', name: 'Select'}, 
                    {id: 'SST', name: 'Inclusive SST'}, 
                    {id: 'EXCSST', name: 'Exclusive SST'}, 
                    {id: 'NOSST', name: 'No SST'},
                    ],
                    rule: [], selected: []
                },
                {carparktaxamount: '', rule: [] },
                {carparkfeereactivation: '', rule: [] },
                {carparkfeelatepaymentpenalty: '', rule: [] },
                {carparkfeereinstatement: '', rule: [] },
                {carparkpaymentmethod: [
                    {id: 'Giro', name: 'Giro'}, 
                    {id: 'Cash (Site)', name: 'Cash (Site)'}, 
                    {id: 'Cheque (Site)', name: 'Cheque (Site)'},
                    {id: 'Online Banking', name: 'Online Banking'},
                    ],
                    rule: [], selected: []
                },
                {carparkactivestatus: false, rule: [] },
                {carparkmobiledisplaystatus: false, rule: [] },
                {carparkuserinputcode: '', rule: [] },
                {file: '', imgrule: [], image: '', btn_loading: '', logo: '' },
                {file: '', imgrule: [], image: '', btn_loading: '', header: '' },
                {file: '', imgrule: [], image: '', btn_loading: '', footer: '' },
                {file: '', imgrule: [], image: '', btn_loading: '', general: '' },
                {carparkdescription: '', rule: [] },
                {carparktype: [
                    {id: '', name: 'Select'}, 
                    {id: 'GATED', name: 'Gated'}, 
                    {id: 'STREET', name: 'Street'},
                    ],
                    rule: [], selected: []
                },
                {carparkmappingid: '', rule: [] }, //code 59
                {createddatetime: '' }, //code 60
                {updateddatetime: '' }, //code 61
                {carparkmanagedbaycount: '', rule: [] }, //code 62
                {carparkwheellockstatus: false, rule: [] }, //code 63
                {bayprefix: '', rule: [] }, //code 64,
                {carparklprwebstatus:false, }, //code 65
                {carparkwebpaymentlprurl: '', rule:[],}, //code 66
                {carparkwlwebstatus: false}, //code 67
                {carparkwebpaymentwlurl: '', rule:[],}, //code 68
                

            ],

            system: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },

            roles: [],
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
            modalInfo: [],
            map: null,
            mapCenter: {lat: 5.502994, lng: 101.700439},
            markers: {
            position: {
              lat: 5.502994,
              lng: 101.700439
            },
          },

          coordinates: null,
          zoom: 9,
          file: '',
          imgrule: [],
          image: '',
          modal_loader: false,
          btn_loading: false,
          componentloader: false,
          postcode: [],
          sinput: '',
          readonly: false,
          mask: timeMask,
          focused: false,
          focusedend: false,
          searchlocation: '',
          focusstarthour: false,
          focusendhour: false,
          swal: {
            notification: false,
            message: [],
            emessage: [],
            scolor: [],
          },
          hasVerified: false
        }
    },

    components: {alertbox, 'text-editor': TextEditor, VueTimepicker, QrcodeVue},

    watch: {
        'markers.position': {
        handler: function(value) {
            this.form[2].latitude = value.lat;
            this.form[3].longitude = value.lng;
        },
        },
    },

    async created(){
        if (!this.validateAccess('carpark_update')){
            this.readonly = true;
        }

        this.$watch(() => this.form[53].file, this.handleImageMain, {deep: true});
        this.$watch(() => this.form[54].file, this.handleImageHeader, {deep: true});
        this.$watch(() => this.form[55].file, this.handleImageFooter, {deep: true});
        this.$watch(() => this.form[56].file, this.handleImageGeneral, {deep: true});
        await this.handleValue();
        this.componentloader = true;
        //this.$vuetify.goTo(0);
    },

    async beforeRouteLeave (to, from , next) {
        AppStorage.remove("cpdetails");
        next();
    },

    methods: {
        async getAllCountry(){
            let list = [];
            try{
                let response = await this.$store.dispatch("listCountry", {search: ''});
                let dt = response.data.record;
                this.form[0].carparkaddresscountry.push({name: "", CountryCode: ""});
                this.form[32].carparkbillingaddresscountry.push({name: "", CountryCode: ""});
                if (response.data.code == "AP000"){
                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].CountryCode} - ${dt[i].CountryName}`;

                        this.form[0].carparkaddresscountry.push(dt[i]);
                        this.form[32].carparkbillingaddresscountry.push(dt[i]);
                    }
                }

                list = dt;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getAllSystem(){
            let list = [];
            this.system.items = [];
            try{
                this.system.loader = true;
                this.system.disable = true;
                let response = await this.$store.dispatch("getAllSystem2", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.system.items.push({name: "Select System", SystemID: ""});
                    this.system.itemsFull.push({name: "Select System", SystemID: ""});
                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].SystemID} - ${dt[i].SystemName}`;
                        this.system.items.push({name: dt[i].name, SystemID: dt[i].SystemID});
                        this.system.itemsFull.push({name: dt[i].name, SystemID: dt[i].SystemID});
                    }
                }

                //this.system.itemsFull = dt;

                //this.system.selected = this.system.items[0].SystemID;

                this.system.loader = false;
                this.system.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getAllOperator(){
            let list = [];
            this.form[35].carparkoperator = [];
            this.form[35].selected = '';
            
            try{
                let response = await this.$store.dispatch("listOperator", {search: ''});
                let dt = response.data.record;
                this.form[35].carparkoperator.push({name: "Select Operator", OperatorID: ""});
                this.form[35].carparkoperatorall.push({name: "Select Operator", OperatorID: ""});
                if (response.data.code == "AP000"){
                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].OperatorID} - ${dt[i].OperatorName}`;

                        this.form[35].carparkoperator.push(dt[i]);
                        this.form[35].carparkoperatorall.push(dt[i]);
                    }

                    if (this.system.selected){
                        let items = [];
                        items = this.form[35].carparkoperator.filter(item => item.SystemID == this.system.selected || item.OperatorID == '');
                        this.form[35].carparkoperator = items;
                    }

                    else{
                        this.form[35].carparkoperator = this.form[35].carparkoperatorall;
                    }
                }

                list = dt;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getAllState(){
            let list = [];
            try{
                let response = await this.$store.dispatch("listState", {search: ''});
                let dt = response.data.record;
                this.form[21].carparkaddressstate.push({name: "", StateCode: ""});
                this.form[31].carparkbillingaddressstate.push({name: "", StateCode: ""});
                if (response.data.code == "AP000"){
                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].StateCode} - ${dt[i].StateName}`;

                        this.form[21].carparkaddressstate.push(dt[i]);
                        this.form[31].carparkbillingaddressstate.push(dt[i]);
                    }
                }

                list = dt;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getAllCity(){
            let list = [];
            try{
                let response = await this.$store.dispatch("listCity", {search: ''});
                let dt = response.data.record;
                this.form[20].carparkaddresscity.push({name: "", CityCode: ""});
                this.form[30].carparkbillingaddresscity.push({name: "", CityCode: ""});
                if (response.data.code == "AP000"){
                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].CityCode} - ${dt[i].CityName}`;

                        this.form[20].carparkaddresscity.push(dt[i]);
                        this.form[30].carparkbillingaddresscity.push(dt[i]);
                    }
                }

                list = dt;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getAllPostcode(){
            let list = [];
            try{
                let response = await this.$store.dispatch("listPostcode", {search: ''});
                let dt = response.data.record;
                if (response.data.code == "AP000"){
                    this.postcode = dt;
                }

                list = dt;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        gMapFunc(evnt) {
            this.markers.position = {"lat":evnt.lat(), "lng":evnt.lng()};
        },

        setPlace(place) {
            if (!place) return
            console.log('t', place);
            this.searchlocation = place.formatted_address;
            this.zoom = 9;
            this.markers.position = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            };

            this.mapCenter = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            };

            this.zoom = 12;
        },

        setPlaceFromAPI(aLat, aLng) {
            this.searchlocation = '';
            if (!aLat) return

            this.zoom = 9;
            this.markers.position = {
            lat: Number(aLat),
            lng: Number(aLng),
            };

            this.mapCenter = {
            lat: Number(aLat),
            lng: Number(aLng),
            };

            this.zoom = 12;
        },

        initMap(){
            this.map = new google.maps.Map(document.getElementById('map'), {
            center: this.mapCenter,
            zoom: true,
            maxZoom: 20,
            minZoom: 3,
            streetViewControl: true,
            mapTypeControl: true,
            fullScreenControl: true,
            zoomControl: true
        });
        },

        onRuleChange(after, before) {
            this.form[0].rule = []; this.form[1].rule = []; this.form[53].rule = []; this.form[54].rule = []; this.form[55].rule = []; this.form[56].rule = [];
            this.form[10].rule = []; this.form[11].rule = []; this.form[36].rule = []; this.form[13].rule = []; this.form[14].rule = []; this.form[8].rule = [];
            this.form[9].rule = []; this.form[33].rule = []; this.form[15].rule = []; this.form[34].rule = []; this.form[12].rule = []; this.form[37].rule = [];
            this.form[49].rule = []; this.form[35].rule = []; this.form[46].rule = []; this.form[47].rule = []; this.form[48].rule = []; this.form[45].rule = [];
            this.form[19].rule = []; this.form[29].rule = []; this.form[40].rulestart = []; this.form[40].ruleend = []; this.form[58].rule = []; this.form[24].rule = [];
        },

         getImageDimensions(file, w, h) {
            if (file != ''){
                let fact;
                return new Promise (function (resolved, rejected) {
                    var i = new Image()
                    i.onload = function(){
                        if (i.width !== w || i.height !== h){
                            fact = false;
                        }

                        else{
                            console.log('truth', 'true');
                            fact = true;
                        }

                        resolved(fact);
                    };

                    i.src = file;
                });
            }

            else{
                return true;
            }
        },

        async beforeCreateForm(flag){
            this.onRuleChange();
            console.log('pass0');
            let checklogo = await this.getImageDimensions(this.form[53].image, 400, 400);
            let checkheader = await this.getImageDimensions(this.form[54].image, 1000, 70);
            let checkfooter = await this.getImageDimensions(this.form[55].image, 1000, 150);
            let checkgeneral = await this.getImageDimensions(this.form[56].image, 1000, 70);

            this.form[35].rule = [
                v => !!v || 'Operator is required',
            ];

            this.form[1].rule = [
                v => !!v || 'This is required',
            ];

            this.form[2].rule = [
                v => !!v || 'Latitude is required',
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            this.form[3].rule = [
                v => !!v || 'Longitude is required',
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            this.form[4].rule = [
                v => !!v || 'Start Hour is required',
            ];

            this.form[5].rule = [
                v => !!v || 'End Hour is required',
            ];

            this.form[53].imgrule = [ 
                //v => !v || v.size < 500000 || 'Image size should be less than 500 KB!',
                v => !v || /(jpe?g|png|gif|bmp)$/i.test(v.name.toString().split('.').pop().toLowerCase()) || 'Only common image format (PNG and JPEG/JPG) format accepted',
                v => !v || checklogo || 'Please ensure image is 400 x 400'
            ];

            this.form[54].imgrule = [
                //v => !v || v.size < 500000 || 'Image size should be less than 500 KB!',
                v => !v || /(jpe?g|png|gif|bmp)$/i.test(v.name.toString().split('.').pop().toLowerCase()) || 'Only common image format (PNG and JPEG/JPG) format accepted',
                v => !v || checkheader || 'Please ensure image is 1000 x 70'
            ];

            this.form[55].imgrule = [
                //v => !v || v.size < 500000 || 'Image size should be less than 500 KB!',
                v => !v || /(jpe?g|png|gif|bmp)$/i.test(v.name.toString().split('.').pop().toLowerCase()) || 'Only common image format (PNG and JPEG/JPG) format accepted',
                v => !v || checkfooter || 'Please ensure image is 1000 x 150'
            ];

            this.form[56].imgrule = [
                //v => !v || v.size < 500000 || 'Image size should be less than 500 KB!',
                v => !v || /(jpe?g|png|gif|bmp)$/i.test(v.name.toString().split('.').pop().toLowerCase()) || 'Only common image format (PNG and JPEG/JPG) format accepted',
                v => !v || checkgeneral || 'Please ensure image is 1000 x 70'
            ];

            this.form[10].rule = [
                v => !v || /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || 'Please enter a valid email address'
            ];

            this.form[11].rule = [
                v => !!v || 'Email is required',
                v => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || 'Please enter a valid email address'
            ];

            this.form[36].rule = [
                v => !v || /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || 'Please enter a valid email address'
            ];

            this.form[13].rule = [
                v => !!v || 'Phone number is required',
                v => !Number.isNaN(Number(v)) || 'Please enter a valid phone no.'
                //v => !v || /^(6{1}0)[0-9]{1,2}[0-9]{7,8}$/.test(v) || 'Please enter a valid phone no.'
            ];

            this.form[14].rule = [
                v => !Number.isNaN(Number(v)) || 'Please enter a valid phone no.'
                //v => !v || /^(6{1}0)[0-9]{1,2}[0-9]{7,8}$/.test(v) || 'Please enter a valid phone no.'
            ];

            this.form[8].rule = [
                v => !v || /^(6{1}0)[0-9]{1,2}[0-9]{7,8}$/.test(v) || 'Please enter a valid phone no.'
            ];

            this.form[9].rule = [
                v => !v || /^(6{1}0)[0-9]{1,2}[0-9]{7,8}$/.test(v) || 'Please enter a valid phone no.'
            ];

            this.form[33].rule = [
                v => !v || /^(\+?6?0)[0-9]{1,2}-*[0-9]{7,8}$/.test(v) || 'Please enter a valid phone no.'
            ];

            this.form[15].rule = [
                v => !v || /^(6{1}0)[0-9]{1,2}[0-9]{7,8}$/.test(v) || 'Please enter a valid fax no.'
            ];

            this.form[34].rule = [
                v => !v || /^(\+?6?0)[0-9]{1,2}-*[0-9]{7,8}$/.test(v) || 'Please enter a valid fax no.'
            ];

            this.form[12].rule = [
                v => !v || /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(v) || "URL must be valid"
            ];

            this.form[37].rule = [
                v => !v || /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(v) || "URL must be valid"
            ];

            this.form[46].rule = [
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            this.form[47].rule = [
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            this.form[48].rule = [
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            this.form[45].rule = [
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            this.form[19].rule = [
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            this.form[58].rule = [
                v => !!v || 'Car Park Type is required',
            ];

            this.form[52].rule = [
                v => !!v || 'CP Code is required',
            ];


            let checkcarparkdesc = /^.*>[^<].*$/.test(this.form[57].carparkdescription);
            this.form[57].rule = [
                v => !v || checkcarparkdesc || 'Parking Rate is required',
            ];

            this.form[39].rule = [
                v => !!v || 'Car Park Location is required',
            ];

            this.form[29].rule = [
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            if (this.form[40].range.start !== null && this.form[40].range.end !== null){
                if (this.form[40].range.end != '' && this.form[40].range.hasOwnProperty('end')){
                    console.log('endtruth', this.form[40].range.end != '' && this.form[40].range.hasOwnProperty('end'));
                    console.log('endtruth1', this.form[40].range.end);
                    console.log('endtruth2', this.form[40].range.hasOwnProperty('end'));
                    this.form[40].rulestart = [
                        v => moment(moment(this.form[40].range.start).format('YYYY-MM-DD')).isBefore(moment(this.form[40].range.end).format('YYYY-MM-DD')) && this.form[40].range.end != '' || 'Date should be before end date'
                    ];
                }

                if (this.form[40].range.start != '' && this.form[40].range.hasOwnProperty('start')){
                    this.form[40].ruleend = [
                        v => !moment(moment(this.form[40].range.end).format('YYYY-MM-DD')).isBefore(moment(this.form[40].range.start).format('YYYY-MM-DD')) || 'Date should be after start date',
                        v => this.form[40].range.start != '' || 'There should be a start date'
                    ];
                }
            }

            this.form[6].rule = [
                v => !!v || 'Total Bay Count is required',
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            this.form[22].rule = [
                v => !!v || 'Total Available Bay is required',
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            if (this.form[44].selected === 'SST'){
                this.form[45].rule = [
                    v => !!v || 'SST (%) is required',
                    v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
                ];

                this.form[24].rule = [
                    v => !!v || 'SST Reg No. is required',
                ];
            }

            else{
                this.form[45].rule = [
                    v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
                ];
            }

            this.form[23].rule = [
                v => !!v || 'Company Name is required',
            ];

            this.form[16].rule = [
                v => !!v || 'Address is required',
            ];

            this.form[26].rule = [
                v => !!v || 'Address is required',
            ];

            let validatepostcode = this.form[20].selected !== "";

            this.form[19].rule = [
                v => !!v || 'Postcode is required',
                v => validatepostcode || 'Postcode not found',
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            let validatepostcode1 = this.form[30].selected !== "";

            this.form[29].rule = [
                v => !!v || 'Postcode is required',
                v => validatepostcode1 || 'Postcode not found',
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            if (this.modalInfo.CarParkBayPrefix !== null && this.modalInfo.CarParkBayPrefix !== ''){

                this.form[64].rule = [
                    v => !!v || 'Bay prefix is required',
                ];
            }

            this.form[44].rule = [
                v => !!v || 'SST Option is required',
            ];

/*
            this.form[49].rule = [
                v => !!v || 'Payment method is required',
            ];
*/
            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            setTimeout(function () {
             if (self.$refs.ct.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                    console.log('pass1');
                }
            });
        },

        async handleValue(){
            this.modal_loader = true;
            if (this.$route.query.row !== undefined) AppStorage.set("cpdetails", this.$route.query.row);
            this.$router.replace({'query': null});
            this.modalInfo = this.$route.query.row || AppStorage.get('cpdetails');
            this.modalInfo = JSON.parse(this.modalInfo);
            this.form[45].carparktaxamount = this.modalInfo.CarParkTaxAmount == '0' ? '0.00' : this.modalInfo.CarParkTaxAmount;
            //for inserting char into string
            String.prototype.insert = function(what, index) {
                return index > 0
                    ? this.replace(new RegExp('.{' + index + '}'), '$&' + what)
                    : what + this;
            };

            this.getAllCountry();
            await this.getAllSystem();
            this.system.selected = this.modalInfo.SystemID;
            this.getAllOperator();
            this.getAllState();
            await this.getAllCity();
            await this.getAllPostcode();
            this.onRuleChange();
            this.form[35].selected = this.modalInfo.OperatorID;
            this.form[1].carparkname = this.modalInfo.CarParkName;
            //this.form[39].carparklocation = this.modalInfo.CarParkLocation;
            this.form[2].latitude = this.modalInfo.CarParkLocationGPSLatitude;
            this.form[3].longitude = this.modalInfo.CarParkLocationGPSLongitude;
            this.form[16].carparkaddress1 = this.modalInfo.CarParkAddress1;
            this.form[17].carparkaddress2 = this.modalInfo.CarParkAddress2;
            this.form[18].carparkaddress3 = this.modalInfo.CarParkAddress3;
            this.form[19].carparkaddresspostcode = this.modalInfo.CarParkAddressPostcode;
            this.form[20].selected = this.modalInfo.CarParkAddressCity;
            this.form[21].selected = this.modalInfo.CarParkAddressState;
            this.form[0].selected = this.modalInfo.CarParkAddressCountry;
            this.form[13].carparktelephoneno1 = this.modalInfo.CarParkTelephoneNo1;
            this.form[14].carparktelephoneno2 = this.modalInfo.CarParkTelephoneNo2;
            this.form[15].carparkfaxno = this.modalInfo.CarParkFaxNo;
            this.form[11].carparkemail = this.modalInfo.CarParkEmail;
            this.form[12].carparkweburl = this.modalInfo.CarParkWebURL;
            this.form[7].carparkcontactpersonname = this.modalInfo.CarParkContactPersonName;
            this.form[10].carparkcontactpersonemail = this.modalInfo.CarParkContactPersonEmail;
            this.form[8].carparksoscontact1 = this.modalInfo.CarParkSOSContact1;
            this.form[9].carparksoscontact2 = this.modalInfo.CarParkSOSContact2;
            this.form[23].carparkbillingcompanyname = this.modalInfo.CarParkBillingCompanyName;
            this.form[25].carparkbillingregno = this.modalInfo.CarParkBillingRegNo;
            this.form[24].carparkbillingsstno = this.modalInfo.CarParkBillingSSTNo;
            this.form[26].carparkbillingaddress1 = this.modalInfo.CarParkBillingAddress1;
            this.form[27].carparkbillingaddress2 = this.modalInfo.CarParkBillingAddress2;
            this.form[28].carparkbillingaddress3 = this.modalInfo.CarParkBillingAddress3;
            this.form[29].carparkbillingaddresspostcode = this.modalInfo.CarParkBillingAddressPostcode;
            this.form[30].selected = this.modalInfo.CarParkBillingAddressCity;
            this.form[31].selected = this.modalInfo.CarParkBillingAddressState;
            this.form[32].selected = this.modalInfo.CarParkBillingAddressCountry;
            this.form[33].carparkbillingtelephoneno = this.modalInfo.CarParkBillingTelephoneNo;
            this.form[34].carparkbillingfaxno = this.modalInfo.CarParkBillingFaxNo;
            this.form[36].carparkbillingemail = this.modalInfo.CarParkBillingEmail;
            this.form[37].carparkbillingweburl = this.modalInfo.CarParkBillingWebURL;
            this.form[38].carparkbillingtermsconditions = this.modalInfo.CarParkBillingTermsConditions;
            this.form[6].carparktotalbaycount = this.modalInfo.CarParkTotalBayCount;
            this.form[22].carparktotalbayavailable = this.modalInfo.CarParkTotalBayAvailable;
            this.form[4].carparkoperationstarthour = moment(this.modalInfo.CarParkOperationStartHour,'HHmm').format('hh:mm a');
            this.form[5].carparkoperationendhour = moment(this.modalInfo.CarParkOperationEndHour,'HHmm').format('hh:mm a');
            this.form[40].range = {};
            this.form[40].range.start = this.modalInfo.CarParkOperationStartDate;
            this.form[40].range.end = this.modalInfo.CarParkOperationEndDate;
            this.form[41].selected = this.modalInfo.CarParkContractType;
            this.form[42].selected = this.modalInfo.CarParkLicenseApplicationCommencementDate;
            this.form[44].selected = this.modalInfo.CarParkTaxOptions;
            this.form[64].bayprefix = this.modalInfo.CarParkBayPrefix;
            //this.form[45].carparktaxamount = this.modalInfo.CarParkTaxAmount;
            //this.form[45].carparktaxamount = this.formatNumber(this.modalInfo.CarParkTaxAmount);
            //this.sinput = this.formatNumber(this.modalInfo.CarParkTaxAmount) * 100;
            this.form[57].carparkdescription = this.modalInfo.CarParkDescription;

            this.form[49].selected = [];

            if (this.modalInfo.CarParkPaymentMethod !== ''){
                let len = this.modalInfo.CarParkPaymentMethod.split(',').length;
                let arr = this.modalInfo.CarParkPaymentMethod.split(',');

                for (let i=0; i<len; i++){
                    console.log(arr);
                    this.form[49].selected.push({id: arr[i], name: arr[i]});
                }
            }

            this.form[43].selected = this.modalInfo.CarParkOptions.split(',');
            this.form[46].carparkfeereactivation = this.modalInfo.CarParkFeeReactivation;
            this.form[47].carparkfeelatepaymentpenalty = this.modalInfo.CarParkFeeLatePaymentPenalty;
            this.form[48].carparkfeereinstatement = this.modalInfo.CarParkFeeReinstatement;
            //this.form[49].selected = this.modalInfo.CarParkPaymentMethod.split(',');
            this.form[53].image = this.modalInfo.CarParkLogoImage;
            this.form[54].image = this.modalInfo.CarParkHeaderImage;
            this.form[55].image = this.modalInfo.CarParkFooterImage;
            this.form[56].image = this.modalInfo.CarParkGeneralDocumentImage;
            this.form[53].file = '';
            this.form[54].file = '';
            this.form[55].file = '';
            this.form[56].file = '';
            this.form[52].carparkuserinputcode = this.modalInfo.CarParkUserInputCode;
            this.form[50].carparkactivestatus = this.modalInfo.ActiveStatus;
            this.form[51].carparkmobiledisplaystatus = this.modalInfo.MobileDisplayStatus;
            this.form[63].carparkwheellockstatus = this.modalInfo.WheelLockStatus;
            this.form[62].carparkmanagedbaycount = this.modalInfo.CarParkTotalManagedBayCount;
            this.form[53].btn_loading = false;
            this.form[54].btn_loading = false;
            this.form[55].btn_loading = false;
            this.form[56].btn_loading = false;
            this.form[58].selected = this.modalInfo.CarParkType == null ? '' : this.modalInfo.CarParkType;
            this.form[60].createddatetime = this.modalInfo.CreatedDateTime;
            this.form[61].updateddatetime = this.modalInfo.UpdatedDateTime;

            this.setPlaceFromAPI(this.modalInfo.CarParkLocationGPSLatitude, this.modalInfo.CarParkLocationGPSLongitude);
            this.form[39].carparklocation = this.modalInfo.CarParkLocation;
            this.form[59].carparkmappingid = this.modalInfo.CarParkMappingID == '' ? 'NA' : this.modalInfo.CarParkMappingID;
            this.form[66].carparkwebpaymentlprurl = this.modalInfo.CarParkLPRWebPaymentName != null ? this.modalInfo.CarParkLPRWebPaymentName : null;
            this.form[68].carparkwebpaymentwlurl = this.modalInfo.CarParkWLWebPaymentName != null ? this.modalInfo.CarParkWLWebPaymentName : null;;
            this.form[65].carparklprwebstatus = this.modalInfo.CarParkLPRWebPaymentName != null ? true : false;
            this.form[67].carparkwlwebstatus = this.modalInfo.CarParkWLWebPaymentName != null ? true : false;
            this.modal_loader = false;
        },

        //set the action to be sent to watcher to execute the create/edit function
        onConfirm(value){
          console.log(value);
                if (this.ma.modal_action_type == 'edit'){
                    this.form.flag = 'update';
                    this.$emit('confirm', this.form);
                    let logoupdate = 'no';
                    let headerupdate = 'no';
                    let footerupdate = 'no';

                    if (this.form[53].file != ''){
                        logoupdate = 'yes';
                    }

                    if (this.form[54].file != ''){
                        headerupdate = 'yes';
                    }

                    if (this.form[55].file != ''){
                        footerupdate = 'yes';
                    }

                    if (this.form[56].file != ''){
                        this.$emit('confirmGeneral', this.form);
                    }

                    this.$router.push({ name: 'Car Park', query: {updated: true, v: JSON.stringify(this.form), id: this.$route.params.id, logo: logoupdate, header: headerupdate, footer: footerupdate}});
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },

        async handleImageMain(v) {
            //console.log(v.name.toString().split('.').pop().toLowerCase())
            console.log('img', v);

            if (v){
            this.form[53].btn_loading = true;
            const selectedImage = v; //get first file
            let base64img = await this.getBase64(selectedImage);
            if (base64img == undefined) base64img = '';

            this.form[53].image = base64img;
            this.form[53].btn_loading = false;
            let check = await this.getImageDimensions(this.form[53].image, 400, 400);
            if (!check){
                this.form[53].image = await this.modalInfo.CarParkLogoImage;
                this.form[53].file = '';
                this.form[53].imgrule = [
                    v => false || 'Please ensure image is 400 x 400'
                ];
                console.log(this.form[53].imgrule);
            }

            else{
                this.form[53].imgrule = [];
            }
            }

            else{
                await this.$nextTick(async () => {
                this.form[53].image = await this.modalInfo.CarParkLogoImage;
                this.form[53].btn_loading = false;
                });
            }
        },

        async handleImageHeader(v) {
            //console.log(v.name.toString().split('.').pop().toLowerCase())
            console.log('img', v);

            if (v){
            this.form[54].btn_loading = true;
            const selectedImage = v; //get first file
            let base64img = await this.getBase64(selectedImage);
            if (base64img == undefined) base64img = '';

            this.form[54].image = base64img;
            this.form[54].btn_loading = false;
            let check = await this.getImageDimensions(this.form[54].image, 1000, 70);
            if (!check){
                this.form[54].image = await this.modalInfo.CarParkHeaderImage;
                this.form[54].file = '';
                this.form[54].imgrule = [
                    v => false || 'Please ensure image is 1000 x 70'
                ];
                console.log(this.form[54].imgrule);
            }

            else{
                this.form[54].imgrule = [];
            }
            }

            else{
                await this.$nextTick(async () => {
                this.form[54].image = await this.modalInfo.CarParkHeaderImage;
                this.form[54].btn_loading = false;
                });
            }
        },

        async handleImageFooter(v) {
            //console.log(v.name.toString().split('.').pop().toLowerCase())
            console.log('img', v);

            if (v){
            this.form[55].btn_loading = true;
            const selectedImage = v; //get first file
            let base64img = await this.getBase64(selectedImage);
            if (base64img == undefined) base64img = '';

            this.form[55].image = base64img;
            this.form[55].btn_loading = false;
            let check = await this.getImageDimensions(this.form[55].image, 1000, 150);
            if (!check){
                this.form[55].image = await this.modalInfo.CarParkFooterImage;
                this.form[55].file = '';
                this.form[55].imgrule = [
                    v => false || 'Please ensure image is 1000 x 150'
                ];
                console.log(this.form[55].imgrule);
            }

            else{
                this.form[55].imgrule = [];
            }
            }

            else{
                await this.$nextTick(async () => {
                this.form[55].image = await this.modalInfo.CarParkFooterImage;
                this.form[55].btn_loading = false;
                });
            }
        },

        async handleImageGeneral(v) {
            //console.log(v.name.toString().split('.').pop().toLowerCase())
            console.log('img', v);

            if (v){
            this.form[56].btn_loading = true;
            const selectedImage = v; //get first file
            let base64img = await this.getBase64(selectedImage);
            if (base64img == undefined) base64img = '';

            this.form[56].image = base64img;
            this.form[56].btn_loading = false;
            let check = await this.getImageDimensions(this.form[56].image, 1000, 70);
            if (!check){
                this.form[56].image = await this.modalInfo.CarParkGeneralDocumentImage;
                this.form[56].file = '';
                this.form[56].imgrule = [
                    v => false || 'Please ensure image is 1000 x 70'
                ];
                console.log(this.form[56].imgrule);
            }

            else{
                this.form[56].imgrule = [];
            }
            }

            else{
                await this.$nextTick(async () => {
                this.form[56].image = await this.modalInfo.CarParkGeneralDocumentImage;
                this.form[56].btn_loading = false;
                });
            }
        },

        getBase64(file) {
            if (file){
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = error => reject(error);
                });
            }
        },

        execFilter(val){
            if (val == 'state1'){
                let a = this.form[20].carparkaddresscity.find(i => i.CityCode == this.form[20].selected);
                this.form[0].selected = a.CountryCode;
                this.form[21].selected = a.StateCode;
            }

            if (val == 'state2'){
                let a = this.form[30].carparkbillingaddresscity.find(i => i.CityCode == this.form[30].selected);
                this.form[32].selected = a.CountryCode;
                this.form[31].selected = a.StateCode;
            }

            if (val == 'postcode1'){
                let a = this.postcode.find(i => i.Postcode == this.form[19].carparkaddresspostcode);
                console.log(a);
                if (a != undefined){
                    this.form[0].selected = a.CountryCode;
                    this.form[21].selected = a.StateCode;
                    this.form[20].selected = a.CityCode;
                }

                else{
                    this.form[0].selected = '';
                    this.form[21].selected = '';
                    this.form[20].selected = '';
                }
            }

            if (val == 'postcode2'){
                let a = this.postcode.find(i => i.Postcode == this.form[29].carparkbillingaddresspostcode);
                console.log(a);
                if (a != undefined){
                    this.form[32].selected = a.CountryCode;
                    this.form[31].selected = a.StateCode;
                    this.form[30].selected = a.CityCode;
                }

                else{
                    this.form[32].selected = '';
                    this.form[31].selected = '';
                    this.form[30].selected = '';
                }
            }
        },

        addDropFile53(e) { if (!this.readonly) this.form[53].file = e.dataTransfer.files[0]; },
        addDropFile54(e) { if (!this.readonly) this.form[54].file = e.dataTransfer.files[0]; },
        addDropFile55(e) { if (!this.readonly) this.form[55].file = e.dataTransfer.files[0]; },
        addDropFile56(e) { if (!this.readonly) this.form[56].file = e.dataTransfer.files[0]; },

        changeOrder(e) {
            e.preventDefault();
            e.stopPropagation();
        },

        removeValStart(e){
            e.preventDefault();
            e.stopPropagation();
            this.form[40].range.start = '';
        },

        removeValEnd(e){
            e.preventDefault();
            e.stopPropagation();
            this.form[40].range.end = '';
        },

        SSTinput(e, item){
            //handle backspace key
            e.preventDefault();
            if(e.keyCode == 8 && this.sinput.length > 0) {
                this.sinput = this.sinput.slice(0, this.sinput.length-1); //remove last digit
                item = this.formatNumber(this.sinput);
            }
            else {
                let key = this.getKeyValue(e.keyCode);
                console.log(key);
                if(key) {
                    this.sinput += key; //add actual digit to the input string
                    item = this.formatNumber(this.sinput); //format input string and set the input box value to it
                }
            }

            this.form[45].carparktaxamount = item;
            return false;
        },

        getKeyValue(keyCode) {
            if(keyCode > 57) { //also check for numpad keys
                keyCode -= 48;
            }
            if(keyCode >= 48 && keyCode <= 57) {
                return String.fromCharCode(keyCode);
            }
        },
        
        formatNumber(input2) {
            console.log('isnan', isNaN(parseFloat(input2)));
            if(isNaN(parseFloat(input2))) {
                console.log('its in');
                return "0.00"; //if the input is invalid just set the value to 0.00
            }
            let num = parseFloat(input2);
            console.log((num / 100).toFixed(2));
            return (num / 100).toFixed(2); //move the decimal up to places return a X.00 format
        },

        goBack(){
            this.$router.push({name: 'Car Park'});
        },

        async noSST(){
            if (this.form[44].selected == 'NOSST'){
                this.mask = '';
                this.form[45].carparktaxamount = '0.00';
                this.mask = await timeMask;
            }

            if (this.form[44].selected == ''){
                this.mask = '';
                this.form[45].carparktaxamount = '0.00';
                this.mask = await timeMask;
            }
        },

        showWheelLockBay(){
            if (this.form[63].carparkwheellockstatus == '0'){
                this.form[64].bayprefix = '';
                this.form[64].rule = [];
            }
        },

        showLPRWeb(){
            if(this.form[65].carparklprwebstatus == false){
                this.form[66].carparkwebpaymentlprurl = '';
            } else {
                this.form[66].carparkwebpaymentlprurl = this.modalInfo.CarParkLPRWebPaymentName;
            }
        },
        
        showWLWeb(){
            if(this.form[67].carparkwlwebstatus == false){
                this.form[68].carparkwebpaymentwlurl = '';
            } else {
                this.form[68].carparkwebpaymentwlurl = this.modalInfo.CarParkWLWebPaymentName;
            }
        },

        async verifyUniqueName(value){
            const response = await this.$store.dispatch('checkUniqueName', value);
            if(response.data.code === 'AP000'){
                this.swal.notification = true;
                if (!this.swal.scolor.includes('success')){
                    this.swal.scolor.push('success');
                    this.hasVerified = true;
                }
                this.swal.message.push(`${ERR.HANDLE(response.data.code)}: Verified url`);
            } else {
                this.swal.notification = true;
                if (!this.swal.scolor.includes('error')){
                    this.swal.scolor.push('error');
                }
                this.swal.message.push(`${ERR.HANDLE(response.data.code)}: Verified url`);
            }

            this.swal.scolor = [];
        },
        async downloadPNG(value){
            this.$nextTick(()=>{
                const can = document.getElementById(value);
                const img = new Image();
                // img.src = can[1].toDataURL();
                img.src = can.childNodes[0].toDataURL();
                console.log('img', img.src);
                const file = this.dataURLtoFile(img.src,`${this.modalInfo.CarParkName}.png`);
                saveAs(file);
            })
        },
        dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type:mime});
        },
        async updateUniqueName(name, type){
            const cred = {
                name,type,carparkid: this.modalInfo.CarParkID
            }
            const response = await this.$store.dispatch('updateUniqueName', cred );
            if(response.data.code =='AP000'){
                if(type == 1){
                    this.modalInfo.CarParkLPRWebPaymentName = name;
                } else if(type == 2){
                    this.modalInfo.CarParkWLWebPaymentName = name;
                }
                AppStorage.set("cpdetails", JSON.stringify(this.modalInfo));
                this.hasVerified = false;
            }
        }
    }
}
</script>